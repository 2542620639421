import { render, staticRenderFns } from "./CategoryBar.vue?vue&type=template&id=0e3c26f4&scoped=true&"
import script from "./CategoryBar.vue?vue&type=script&lang=js&"
export * from "./CategoryBar.vue?vue&type=script&lang=js&"
import style0 from "./CategoryBar.vue?vue&type=style&index=0&id=0e3c26f4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e3c26f4",
  null
  
)

export default component.exports